import React from 'react'
import PropTypes from 'prop-types'

const Testimonials = ({ children }) => {
    return <div className="testimonials">{children}</div>
}

Testimonials.propTypes = {
    children: PropTypes.node.isRequired,
}

export { Testimonials }
