import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import htmr from 'htmr'

const MainHero = ({ title, description, image, alt, large }) => {
    const classes = {
        largeHero: large ? 'hero--lg' : '',
        largeCard: large ? 'hero__card--lg' : '',
    }

    return (
        <div className={`hero ${classes.largeHero}`}>
            <div className="hero__overlay">
                <Img
                    fluid={image}
                    className={'hero__background-img'}
                    alt={alt}
                />
            </div>
            <div className="container">
                <div className="hero__content">
                    <div className={`hero__card ${classes.largeCard}`}>
                        <h1 className="headline headline--h1 headline--light">
                            {htmr(title)}
                        </h1>
                        <p className="hero__card-content">
                            {htmr(description)}
                        </p>
                        <span className="icon icon--arrow-down icon--white" />
                    </div>
                </div>
            </div>
        </div>
    )
}

MainHero.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export { MainHero }
