import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container, SectionHeader } from '../Common'
import ServiceCard from './ServiceCard'
import ServiceSlider from './ServiceSlider'

const ServiceSection = ({ services }) => {
    const data = services.edges

    return (
        <Section>
            <Container>
                <SectionHeader>OUR SERVICES</SectionHeader>
            </Container>

            {/* Slider For Desktop */}
            <div className="service-section service-section--no-container">
                <ServiceSlider services={services} />
            </div>

            {/* Cards for mobile */}
            <Container fullBleed className="hide-up--sm">
                <div className="service-section">
                    {data &&
                        data.map((service, index) => {
                            const { slug, acf_homeServiceCard } = service.node

                            return (
                                <ServiceCard
                                    key={index}
                                    link={slug}
                                    title={acf_homeServiceCard.cardTitle}
                                    description={
                                        acf_homeServiceCard.cardDescription
                                    }
                                    image={
                                        acf_homeServiceCard.cardImage
                                            .sourceUrlSharp.childImageSharp
                                            .fluid
                                    }
                                    alt={acf_homeServiceCard.cardImage.altText}
                                />
                            )
                        })}
                </div>
            </Container>
        </Section>
    )
}

ServiceSection.propTypes = {}

export { ServiceSection }
