import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import htmr from 'htmr'

const ServiceCard = ({ title, description, image, alt, link }) => {
    return (
        <Link to={link} className="service-section__card">
            <Img
                fluid={image}
                alt={alt}
                className="service-section__card-img"
            />
            <div className="service-section__card-body">
                <div className="service-section__card-title">{htmr(title)}</div>
                <p className="service-section__card-content">
                    {htmr(description)}
                </p>
                <div className="service-section__card-cta">
                    <p>READ MORE</p>
                    <div className="icon icon--arrow-right icon--white ml-10" />
                </div>
            </div>
        </Link>
    )
}

ServiceCard.propTypes = {}

export default ServiceCard
