import React from 'react'
import { Link } from 'gatsby'
import { Container } from '../Common'
import CheckIcon from '../../assets/images/icons/cloud-check.svg'

const ThankYouHero = props => {
    return (
        <div class="hero">
            <div class="hero__overlay hero__overlay--secondary">
                <Container>
                    <img class="hero__icon" src={CheckIcon} />
                </Container>
            </div>

            <Container>
                <div class="hero__content">
                    <div class="hero__card hero__card--middle">
                        <h1 class="headline headline--h1 headline--light">
                            THANK YOU
                            <br />
                            FOR YOUR
                            <br />
                            SUBMISSION.
                        </h1>
                        <p class="hero__card-content">
                            A representative will get back to you shortly.
                        </p>
                        <Link
                            to="/"
                            class="book-section__info-button book-section__info-button--sm book-section__info-button--white"
                        >
                            RETURN HOME <i class="icon icon--arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export { ThankYouHero }
