import React from 'react'
import PropTypes from 'prop-types'

const TestimonialCard = ({ name, position, testimonial }) => {
    return (
        <div className="testimonials__card">
            <div className="testimonials__card-header">
                <div className="icon icon--quote icon--lg"></div>
                <div className="icon icon--stars"></div>
            </div>
            <div className="testimonials__card-content">{testimonial}</div>
            <div className="testimonials__card-footer">
                <div className="testimonials__card-footer-name">{name}</div>
                <div className="testimonials__card-footer-job-title">
                    {position}
                </div>
            </div>
        </div>
    )
}

TestimonialCard.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    testimonial: PropTypes.string.isRequired,
}

export { TestimonialCard }
