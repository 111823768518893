import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import { MainHero } from '../components/Hero'
import { Section, Container } from '../components/Common'
import { Testimonials, TestimonialCard } from '../components/Testimonials'
import { ServiceSection } from '../components/ServiceSection'
import { ImageBlockSection } from '../components/ImageBlock'
import { LetsConnectForm } from '../components/FormSections'

import { useHomePage } from '../GraphQL/Queries'
import SlidingHero from '../components/Hero/SlidingHero'

const IndexPage = () => {
    const data = useHomePage()

    const {
        acf_hero,
        acf_largeImageBlock,
        acf_testimonials,
        acf_connectForm,
        title,
    } = data.pageBy

    const hero = {
        image: acf_hero.sliderImages,
        description: acf_hero.bbDescription,
        title: acf_hero.bbTitle,
    }

    const imageBlock = {
        title: acf_largeImageBlock.boxTitle,
        headline: acf_largeImageBlock.title,
        description: acf_largeImageBlock.description,
        image: acf_largeImageBlock.image.sourceUrlSharp.childImageSharp.fluid,
    }

    const connectForm = {
        title: acf_connectForm.connectTitle,
        description: acf_connectForm.connectDescription,
        image:
            acf_connectForm.connectImage.sourceUrlSharp.childImageSharp.fluid,
    }

    return (
        <Layout>
            <SEO title={title} />
            <SlidingHero
                description={hero.description}
                title={hero.title}
                images={hero.image}
            />
            <ServiceSection services={data.services} />
            <ImageBlockSection
                headline={imageBlock.headline}
                description={imageBlock.description}
                image={imageBlock.image}
            />
            <Section>
                <Container fullBleed>
                    <Testimonials>
                        {acf_testimonials &&
                            acf_testimonials.cards.map((card, index) => (
                                <TestimonialCard
                                    key={index}
                                    name={card.name}
                                    position={card.position}
                                    testimonial={card.description}
                                />
                            ))}
                    </Testimonials>
                </Container>
            </Section>
            <Section>
                <LetsConnectForm
                    image={connectForm.image}
                    title={connectForm.title}
                    description={connectForm.description}
                />
            </Section>
        </Layout>
    )
}

export default IndexPage
