import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import htmr from 'htmr'

SwiperCore.use([Pagination, Navigation])

const SlidingHero = ({ title, description, images }) => {
    const settings = {
        pagination: {
            el: '.hero__swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.hero__swiper-button-next',
            prevEl: '.hero__swiper-button-prev',
        },
    }
    return (
        <div className="hero">
            <div className="hero__overlay">
                <div className="hero__slider">
                    <Swiper
                        pagination={settings.pagination}
                        navigation={settings.navigation}
                        loop
                        autoplay
                    >
                        {images &&
                            images.map((image, index) => (
                                <SwiperSlide>
                                    <Img
                                        fluid={
                                            image.image.sourceUrlSharp
                                                .childImageSharp.fluid
                                        }
                                        className="hero__background-img"
                                        alt={image.image.altText}
                                        loading="auto"
                                    />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <div className="container">
                        <div className="hero__swiper-controls">
                            <div className="hero__swiper-pagination" />
                            <div className="hero__swiper-buttons">
                                <div className="hero__swiper-button-prev">
                                    <div className="icon icon--arrow-left icon--white" />
                                </div>
                                <div className="hero__swiper-button-next">
                                    <div className="icon icon--arrow-right icon--white" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="hero__content">
                    <div className="hero__card">
                        <h1 className="headline headline--h1 headline--light">
                            {htmr(title)}
                        </h1>
                        <p className="hero__card-content">
                            {htmr(description)}
                        </p>
                        <span className="icon icon--arrow-down icon--white" />
                    </div>
                </div>
            </div>
        </div>
    )
}

SlidingHero.propTypes = {}

export default SlidingHero
