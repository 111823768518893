import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import ServiceCard from './ServiceCard'

SwiperCore.use([Pagination, Navigation])

const ServiceSlider = ({ services }) => {
    const data = services.edges
    const breakpoints = {
        320: { slidesPerView: 1 },
        991: { slidesPerView: 2 },
        1350: { slidesPerView: 3 },
    }
    return (
        <Swiper
            slidesPerView={3}
            spacebetweenslides={10}
            breakpoints={breakpoints}
            loop
            pagination
            navigation
        >
            {data &&
                data.map((service, index) => {
                    const { slug, acf_homeServiceCard } = service.node
                    return (
                        <SwiperSlide key={index}>
                            <ServiceCard
                                link={slug}
                                title={acf_homeServiceCard.cardTitle}
                                description={
                                    acf_homeServiceCard.cardDescription
                                }
                                image={
                                    acf_homeServiceCard.cardImage.sourceUrlSharp
                                        .childImageSharp.fluid
                                }
                                alt={acf_homeServiceCard.cardImage.altText}
                            />
                        </SwiperSlide>
                    )
                })}
        </Swiper>
    )
}

ServiceSlider.propTypes = {}

export default ServiceSlider
