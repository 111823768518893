import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../Common/Container'
import Img from 'gatsby-image'

const ContactHero = ({ children, image, alt }) => {
    return (
        <div className="hero hero--with-form">
            <div className="hero__overlay">
                <Img
                    fluid={image}
                    className="hero__background-img hero__background-img--left"
                    alt={alt}
                />
            </div>
            <Container>
                <div className="hero__content">{children}</div>
            </Container>
        </div>
    )
}

ContactHero.propTypes = {}

export { ContactHero }
